.work {
  padding: 0 1.6rem;

  .work__container {
    max-width: var(--mx);
    margin: 0 auto;

    .small__text {
      margin: 2.4rem 0 2.4rem auto;
      max-width: 65rem;
      width: 100%;
      line-height: 24px;
    }

    h2 {
      font-size: 4.8rem;
      font-weight: 400;
      color: var(--black-alt);
      margin-top: var(--xl);

      @media screen and (max-width: 768px) {
        font-size: 3rem;
        margin-bottom: -1rem;
      }

      @media screen and (max-width: 375px) {
        font-size: 2.5rem;
        margin-bottom: -2rem;
      }
    }
  }
}
