.pagination__container {
  width: 100%;
  padding: 0 1.5rem;
  margin-top: var(--xl);

  .pagination {
    max-width: var(--mx);
    margin: 0 auto;
    width: 100%;
    border-top: var(--border);
    padding-top: 6.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    .button__pag {
      display: flex;
      align-items: center;
      width: 39.5rem;
      height: 13rem;
      border: var(--border);
      border-radius: 4px;
      cursor: var(--pointer);
      padding: 4rem 2.4rem;

      div {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        p {
          color: var(--black);
          font-family: var(--regular);
          font-weight: normal;
          font-size: 1.6rem;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        h4 {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          color: var(--black);
          font-family: var(--sBold);
          font-weight: 600;
          font-size: clamp(1.5rem, 4vw, 2.8rem);
        }
      }

      span {
        width: clamp(2rem, 7vw, 4rem);
        height: clamp(2rem, 7vw, 4rem);
        margin-left: auto;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .prev {
      margin-right: auto;
      margin-left: 0;
    }

    .next {
      margin-left: auto;
      margin-right: 0;
    }

    @media screen and (max-width: 600px) {
      .button__pag {
        width: max-content;
        height: auto;
        padding: 1.5rem;
      }
    }
  }
}
