.home__container {
  width: 100%;
  padding: 0 1.5rem;

  .home__content {
    max-width: var(--mx);
    margin: 0 auto var(--xl);

    .home__content-bottom {
      width: 90%;
      max-width: 85.2rem;
      margin-left: auto;

      h1 {
        margin-top: -5.5rem;

        @media screen and (max-width: 760px) {
          margin-top: -2rem;
        }

        @media screen and (max-width: 600px) {
          margin-top: 0;
        }
      }

      .small__text {
        margin: 2.4rem 0.5rem 0;
        max-width: 60rem;
        width: 100%;
      }
    }
  }
}
