.contact {
  padding: 0 1.6rem 14rem;

  .contact__container {
    max-width: var(--mx);
    margin: 0 auto;

    .contact__info {
      display: flex;
      justify-content: flex-end;

      div {
        max-width: var(--cw);
        width: 100%;

        p {
          font-size: 2.4rem;
          font-family: var(--regular);
          font-weight: normal;
          color: var(--black-alt);
          padding-top: 2.4rem;

          span,
          a {
            font-family: inherit;
            color: var(--orange);
          }

          a {
            text-decoration: underline;
          }
        }

        @media screen and (max-width: 768px) {
          max-width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 5rem;
  }
}
