.variations {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: var(--xl) 0 12.8rem;

  .filter_buttons {
    height: 6.4rem;
    width: 100%;

    div {
      background-color: var(--white-alt);
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      button {
        width: 50%;
        height: 100%;
        position: relative;
        z-index: 1;

        p {
          color: var(--black);
          font-size: clamp(1.3rem, 2vw, 1.8rem);
          font-family: var(--regular);
          font-weight: normal;
          transition: color 0.2s ease;
          z-index: 1;
        }

        &::after {
          z-index: -1;
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 89%;
          height: 0;
          background-color: var(--orange);
          transition: height 0.2s ease, width 0.3s ease;
        }

        &:hover {
          p {
            color: var(--white);
          }

          &::after {
            height: 100%;
            width: 100%;
          }
        }
      }

      .activeButton {
        &::after {
          height: 0.5rem;
        }

        &:hover {
          p {
            color: var(--black);
          }

          &::after {
            width: 89%;
            height: 0.5rem;
          }
        }
      }
    }
  }

  .filtered {
    margin-top: 2rem;
    display: grid;
    margin-inline: auto;
    max-width: 144rem;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
    grid-gap: 2rem;

    .filtered_image {
      background-color: var(--white-alt);
      aspect-ratio: 1.3;

      img {
        object-fit: cover;
      }
    }

    @media screen and (max-width: 768px) {
      margin-top: 1.5rem;
      grid-gap: 1.5rem;
      grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 1.5rem;
  }
}
